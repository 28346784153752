import React from 'react';
import TrainingBlock from '../../shared/TrainingBlock.tsx';
import { CompletedActivity as CompletedActivityType } from '../../../types/activity';
import { formatDistance, formatPace } from '../../../utils/activityUtils.ts';

interface Props {
  activity: CompletedActivityType;
}

const CompletedActivity: React.FC<Props> = ({ activity }) => (
  <div className="space-y-6">
    {/* Overview Section */}
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <div className="p-3 bg-gradient-to-br from-green-400 to-green-500 rounded-2xl shadow-sm">
          <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
              d="M13 10V3L4 14h7v7l9-11h-7z" 
            />
          </svg>
        </div>
        <div>
          <div className="font-semibold text-gray-900 text-lg">{activity.type}</div>
          <div className="text-sm text-gray-500 flex items-center space-x-1">
            <span>Actual Pace:</span>
            <span className="font-medium text-gray-700">{formatPace(activity.actualPace)}</span>
          </div>
        </div>
      </div>
      <div className="text-right">
        <div className="text-3xl font-bold bg-gradient-to-r from-green-500 to-green-600 text-transparent bg-clip-text">
          {formatDistance(activity.blocks.reduce((total, block) => total + Number(block.distance), 0))} km
        </div>
        <div className="text-sm text-gray-500">Total Distance</div>
      </div>
    </div>

    {/* Training Blocks */}
    {activity.blocks.length > 0 && (
      <div className="space-y-3">
        <div className="text-sm font-medium text-gray-500">Training Blocks</div>
        <div className="space-y-3 bg-gray-50/50 p-4 rounded-xl">
          {activity.blocks.map((block, index) => (
            <TrainingBlock 
              key={index}
              block={block}
              index={index}
              defaultPace={activity.pace}
            />
          ))}
        </div>
      </div>
    )}

    {/* Training Notes */}
    {activity.notes && (
      <div className="space-y-2">
        <div className="text-sm font-medium text-gray-500">Notes</div>
        <div className="bg-gray-50/50 backdrop-blur-sm rounded-xl p-4 text-gray-700">
          {activity.notes}
        </div>
      </div>
    )}
  </div>
);

export default CompletedActivity;
