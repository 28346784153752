import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';
import { api } from './services/api';
import Dashboard from './components/Dashboard/Dashboard.tsx';
import ActivityDetails from './pages/ActivityDetails.js';
import { ApiProvider } from './context/ApiContext.tsx';
import Login from './components/Login/Login';
import AddGoalPage from './pages/AddGoalPage.tsx';
import SettingsPage from './pages/SettingsPage.tsx';
import TrainingPlan from './pages/TrainingPlan.tsx';
import BaselineCheck from './components/Baseline/BaselineCheck.tsx';
import WeekOverview from './pages/WeekOverview.tsx';

function App() {
  const [token, setToken] = useState(localStorage.getItem('jwt'));

  // Check for token in URL when redirected back from backend
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const jwt = params.get('token');
    
    if (jwt) {
      localStorage.setItem('jwt', jwt);
      setToken(jwt);
      window.location.href = '/dashboard';
    }
  }, []);

  const LoginPage = () => <Login onLogin={() => window.location.href = api.auth.getLoginUrl()} />;

  // Protected route wrapper with BaselineCheck
  const ProtectedRoute = ({ children }) => {
    if (!token) return <Navigate to="/" />;
    
    return <BaselineCheck>{children}</BaselineCheck>;
  };

  return (
    <ApiProvider>
      <BrowserRouter>
        <div className="App overflow-x-hidden">
          <Routes>
            <Route path="/" element={!token ? <LoginPage /> : <Navigate to="/dashboard" />} />
            <Route path="/logout" element={
              <div onLoad={() => api.auth.logout()}>
                Logging out...
              </div>
            } />
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/activities/:date" 
              element={
                <ProtectedRoute>
                  <ActivityDetails />
                </ProtectedRoute>
              } 
            />
            <Route path="/add-goal" element={<ProtectedRoute><AddGoalPage /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
            <Route path="/training" element={<ProtectedRoute><TrainingPlan /></ProtectedRoute>} />
            <Route path="/week/:weekId" element={<ProtectedRoute><WeekOverview /></ProtectedRoute>} />
          </Routes>
        </div>
      </BrowserRouter>
    </ApiProvider>
  );
}

export default App;
