import React, { createContext, useContext, useState, useEffect } from 'react';
import { api } from '../services/api';
import { CompletedActivity, PlannedActivity } from '../types/activity';

export interface Goal {
  id?: string;
  name: string;
  details: string;
  distance: number;
  targetPace?: string;  // Optional: in format "MM:SS"
  targetTime?: string;  // Optional: in format "HH:MM:SS"
  date: string;
  type: 'primary' | 'support';  // Add the new type field
}

export enum WeekPlanPhase {
  BASE = 'base',
  BUILD = 'build-up',
  PEAK = 'peak',
  TAPER = 'taper',
  RACEWEEK = 'race week',
}

export interface WeekPlan {
  id: string;
  week_number: number;
  year: number;
  target_distance: number;
  phase: WeekPlanPhase;
  focus: string;
  notes: string;
  keyWorkouts: string[];
  feedback?: string;
}

export interface WeekFeedbackRequest {
  feedback: string;
}

interface ApiContextType {
  activities: CompletedActivity[];
  plannedActivities: PlannedActivity[];
  weekPlan: WeekPlan[];
  userDetails: any; // TODO: Add proper user type
  loading: boolean;
  error: string | null;
  refreshActivities: () => Promise<void>;
  refreshPlannedActivities: () => Promise<void>;
  refreshUserDetails: () => Promise<void>;
  fetchAllData: () => Promise<void>;
  postGoal: (goal: Goal) => Promise<void>;
  goals: Goal[];
  fetchGoals: () => Promise<void>;
  trainingDays: any;
  removeGoal: (goalId: string) => Promise<void>;
  updateTrainingDays: (days: any) => Promise<void>;
  fetchTrainingDays: () => Promise<void>;
  refreshWeekPlan: () => Promise<void>;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

// Update the interface
interface TrainingSettings {
  trainingDays: number[];
  longRunDay: number;
}

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activities, setActivities] = useState<CompletedActivity[]>([]);
  const [plannedActivities, setPlannedActivities] = useState<PlannedActivity[]>([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [trainingDays, setTrainingDays] = useState<TrainingSettings>({
    trainingDays: [],
    longRunDay: 6 // Default to Saturday
  });
  const [weekPlan, setWeekPlan] = useState<WeekPlan[] | null>(null);
  const handleApiError = (err: any) => {
    console.log(err);
    console.error('API Error:', err);
    setError(err instanceof Error ? err : new Error('Failed to fetch data'));
  };

  const fetchAllData = async () => {
    try {
      setLoading(true);
      setError(null);
      const [activitiesData, plannedData, userData, weekPlanData] = await Promise.all([
        api.activities.getPastActivities(),
        api.activities.getPlannedActivities(),
        api.users.getCurrentUser(),
        api.weekPlan.getWeekPlan()
      ]);

      setActivities(activitiesData);
      setPlannedActivities(plannedData);
      setUserDetails(userData);
      setWeekPlan(weekPlanData);
      setError(null);
    } catch (err: any) {
      handleApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const refreshActivities = async () => {
    try {
      const data = await api.activities.getPastActivities();
      setActivities(data);
    } catch (err: any) {
      handleApiError(err);
    }
  };

  const refreshPlannedActivities = async () => {
    try {
      const data = await api.activities.getPlannedActivities();
      setPlannedActivities(data);
    } catch (err: any) {
      handleApiError(err);
    }
  };

  const refreshUserDetails = async () => {
    try {
      const data = await api.users.getCurrentUser();
      setUserDetails(data);
    } catch (err: any) {
      handleApiError(err);
    }
  };

  const refreshWeekPlan = async () => {
    try {
      const data = await api.weekPlan.getWeekPlan();
      setWeekPlan(data);
    } catch (err: any) {
      handleApiError(err);
    }
  };

  const postGoal = async (goal: any) => {
    try {
      await api.goals.postGoal(goal);
      await fetchGoals();
    } catch (err: any) {
      handleApiError(err);
    }
  };

  const fetchGoals = async () => {
    try {
      const data = await api.goals.getGoals();
      setGoals(data);
    } catch (err: any) {
      handleApiError(err);
    }
  };

  const removeGoal = async (goalId: string) => {
    try {
      await api.goals.deleteGoal(goalId);
      // Update local state after successful deletion
      setGoals(prevGoals => prevGoals.filter(goal => goal.id !== goalId));
    } catch (err: any) {
      handleApiError(err);
      throw err; // Re-throw to handle in the component
    }
  };

  const fetchTrainingDays = async () => {
    try {
      const data = await api.settings.getTrainingDays();
      setTrainingDays(data);
    } catch (err: any) {
      handleApiError(err);
    }
  };

  const updateTrainingDays = async (days: TrainingSettings) => {
    try {
      await api.settings.updateTrainingDays(days);
      setTrainingDays(days);
    } catch (err: any) {
      handleApiError(err);
      throw err; // Re-throw to handle in the component
    }
  };

  useEffect(() => {
    fetchAllData();
    fetchGoals();
    fetchTrainingDays(); // Fetch training days on initial load
    // eslint-disable-next-line
  }, []);

  const value = {
    activities,
    plannedActivities,
    userDetails,
    loading,
    error,
    refreshActivities,
    refreshPlannedActivities,
    refreshUserDetails,
    fetchAllData,
    postGoal,
    goals,
    weekPlan,
    fetchGoals,
    trainingDays,
    removeGoal,
    updateTrainingDays,
    fetchTrainingDays,
    refreshWeekPlan,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};
