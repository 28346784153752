import React, { useState } from 'react';
import ronnyThinking from '../../assets/coach/stretching.png';
import { api } from '../../services/api';

const BaselineForm = () => {
  const [duration, setDuration] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const today = new Date().toISOString().split('T')[0];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await api.users.setBaseline({
        distance: 10,
        duration,
        date: today,
      });
      
      // Refresh user data to update hasBaseline
      window.location.reload();
    } catch (error) {
      console.error('Failed to set baseline:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-orange-500 to-orange-400 p-6">
      <div className="w-full max-w-md space-y-8">
        {/* Ronny and Title */}
        <div className="text-center">
          <div className="flex justify-center mb-4">
            <img 
              src={ronnyThinking} 
              alt="Ronny" 
              className="w-32 h-32 rounded-full bg-white/10 p-2"
            />
          </div>
          <h2 className="text-3xl font-bold text-white mb-2">One Last Thing!</h2>
          <p className="text-white/90 text-lg">
            What's the fastest 10K you can run right now? 
            This helps me create the perfect training plan for you! 🎯
          </p>
        </div>

        {/* Form */}
        <div className="bg-white rounded-2xl shadow-xl p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Distance Field */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Distance
              </label>
              <input
                type="text"
                value="10 km"
                readOnly
                className="w-full px-4 py-3 rounded-xl bg-gray-50 border border-gray-200 text-gray-500"
              />
            </div>

            {/* Duration Field */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Duration (HH:MM:SS)
              </label>
              <input
                type="text"
                placeholder="00:45:00"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                pattern="^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$"
                required
                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
              />
              <p className="mt-1 text-sm text-gray-500">
                Enter your best realistic time
              </p>
            </div>

            {/* Date Field */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date
              </label>
              <input
                type="date"
                value={today}
                readOnly
                className="w-full px-4 py-3 rounded-xl bg-gray-50 border border-gray-200 text-gray-500"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-orange-500 text-white py-3 px-6 rounded-xl font-semibold
                       hover:bg-orange-600 transition-colors disabled:opacity-50"
            >
              {isSubmitting ? 'Saving...' : 'Set Baseline'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BaselineForm; 