import React from 'react';
import { formatDistanceFromMeters } from '../../../utils/activityUtils.ts';
import runningCoach from '../../../assets/coach/running.png';
import sleepingCoach from '../../../assets/coach/sleeping.png';
import stretchingCoach from '../../../assets/coach/stretching.png';
import thumbsUpCoach from '../../../assets/coach/thumbs_up.png';
import twoThumbsUpCoach from '../../../assets/coach/two_thumbs_up.png';
import { PlannedActivity } from '../../../types/activity';
import { User } from '../../../types/user';

interface Props {
  user: User;
  plannedActivities: PlannedActivity[];
}

const CoachMessage: React.FC<Props> = ({ user, plannedActivities }) => {
  if (!user) {
    return (
      <div className="bg-white/90 backdrop-blur-sm rounded-xl p-6 sm:p-8 animate-pulse">
        <div className="flex items-start space-x-6">
          <div className="w-20 h-20 bg-white/20 rounded-2xl" />
          <div className="flex-1 space-y-3">
            <div className="h-8 bg-white/20 rounded-lg w-3/4" />
            <div className="h-4 bg-white/20 rounded w-1/2" />
          </div>
        </div>
      </div>
    );
  }
  
  // Get today's training if any
  const today = new Date().toISOString().split('T')[0];
  const todaysTraining = plannedActivities?.find(
    training => training.date.startsWith(today)
  );

  // Helper to get greeting based on time of day
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Rise and shine";
    if (hour < 18) return "Hey there";
    return "Evening champ";
  };

  // Get appropriate coach image based on context
  const getCoachImage = () => {
    if (!todaysTraining) return sleepingCoach;
    
    // If it's a high intensity workout (any block faster than target pace)
    const isHighIntensity = todaysTraining.blocks.some(
      block => block.pace < todaysTraining.targetPace
    );
    if (isHighIntensity) return runningCoach;
    
    // If it's a recovery or easy day
    const isEasyDay = todaysTraining.blocks.every(
      block => block.pace > todaysTraining.targetPace
    );
    if (isEasyDay) return stretchingCoach;
    
    // For completed workouts or special achievements
    if (todaysTraining.status === 'completed') return twoThumbsUpCoach;
    
    // Default for normal training days
    return thumbsUpCoach;
  };

  // Get motivational message based on context
  const getMotivationalMessage = () => {
    if (!todaysTraining) {
      return "Take time to recover and come back stronger!";
    }
    
    const isHighIntensity = todaysTraining.blocks.some(
      block => block.pace < todaysTraining.targetPace
    );
    
    if (isHighIntensity) {
      return "Time to push your limits! You've got this! 💪";
    }
    
    const isEasyDay = todaysTraining.blocks.every(
      block => block.pace > todaysTraining.targetPace
    );
    
    if (isEasyDay) {
      return "Easy runs build strong foundations! 🌟";
    }
    
    return "Ready for another great training session? Let's go! 🎯";
  };

  return (
    <div className="p-4 sm:p-6">
      <div className="flex items-center space-x-4">
        <div className="w-16 h-16 flex-shrink-0 rounded-full overflow-hidden bg-white/90 p-1.5 shadow-lg">
          <img 
            src={getCoachImage()} 
            alt="Coach" 
            className="w-full h-full object-contain transform hover:scale-105 transition-transform duration-200"
          />
        </div>
        <div className="flex-1 min-w-0">
          <div className="space-y-0.5">
            <h2 className="text-lg font-semibold text-white truncate">
              {getGreeting()}, {user.username}
            </h2>
            <p className="text-sm text-white/80">
              {getMotivationalMessage()}
            </p>
          </div>
        </div>
      </div>

      {todaysTraining ? (
        <div className="mt-4">
          <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-4 shadow-lg">
            <div className="flex items-center justify-between mb-3">
              <span className="text-white/90 font-medium">Today's Training</span>
              <span className="px-3 py-1 bg-white/20 rounded-full text-xs font-medium text-white">
                {todaysTraining.type}
              </span>
            </div>
            
            <div className="grid grid-cols-2 gap-3">
              <div className="bg-white/5 rounded-xl p-3">
                <div className="flex items-center text-white/80 text-sm mb-1">
                  <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M13 10V3L4 14h7v7l9-11h-7z" 
                    />
                  </svg>
                  Distance
                </div>
                <div className="text-white font-semibold">
                  {formatDistanceFromMeters(
                    todaysTraining.blocks.reduce((total, block) => total + Number(block.distance), 0) * 1000
                  )}
                </div>
              </div>

              <div className="bg-white/5 rounded-xl p-3">
                <div className="flex items-center text-white/80 text-sm mb-1">
                  <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" 
                    />
                  </svg>
                  Target Pace
                </div>
                <div className="text-white font-semibold">
                  {todaysTraining.targetPace}/km
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-4">
          <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-4 shadow-lg">
            <div className="flex items-center space-x-3 text-white/90">
              <span className="text-xl">🌟</span>
              <p className="text-sm font-medium">
                Rest day today! Perfect time to recover and prepare for your next session.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachMessage;