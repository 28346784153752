import React from 'react';
import { PlannedActivity } from '../../../types/activity';
import TrainingCard from '../../Training/TrainingCard.tsx';

interface Props {
  training?: PlannedActivity;
} 

const NextTraining: React.FC<Props> = ({ training }) => {
  if (!training) {
    return (
      <div className="bg-white/90 backdrop-blur-sm rounded-2xl p-6 shadow-sm border border-orange-100">
        <h2 className="text-lg font-semibold text-gray-900 mb-2">Next Training</h2>
        <div className="flex items-center space-x-3 text-gray-500">
          <span className="text-2xl">🌅</span>
          <p className="text-sm">No upcoming training scheduled</p>
        </div>
      </div>
    );
  }

  return <TrainingCard training={training} />;
};

export default NextTraining;