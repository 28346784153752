import React, { useState } from 'react';
import TrainingBlock from '../../shared/TrainingBlock.tsx';
import { PlannedActivity as PlannedActivityType, TrainingType } from '../../../types/activity';
import { formatDistance } from '../../../utils/activityUtils.ts';
import { api } from '../../../services/api';
import RegenerateFeedback from './RegenerateFeedback.tsx';
import { useApi } from '../../../context/ApiContext.tsx';

interface Props {
  activity: PlannedActivityType;
}

const trainingTypeConfig: Record<TrainingType, { title: string; icon: string; description: string }> = {
  interval: {
    title: 'Interval Training',
    icon: '⚡',
    description: 'High intensity intervals to improve speed'
  },
  tempo: {
    title: 'Tempo Run',
    icon: '🏃‍♂️',
    description: 'Sustained effort to build endurance'
  },
  long: {
    title: 'Long Run',
    icon: '🌟',
    description: 'Building endurance at conversation pace'
  },
  easy: {
    title: 'Easy Run',
    icon: '🌱',
    description: 'Recovery focused training'
  },
  recovery: {
    title: 'Recovery Run',
    icon: '🔄',
    description: 'Light effort to aid recovery'
  },
  race: {
    title: 'Race',
    icon: '🏁',
    description: 'Race day!'
  }
};

const PlannedActivity: React.FC<Props> = ({ activity }) => {
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [regenerateSuccess, setRegenerateSuccess] = useState(false);
  const { refreshPlannedActivities } = useApi();

  const typeConfig = trainingTypeConfig[activity.type as TrainingType];
  
  const handleRegenerate = async (reason: string) => {
    try {
      setIsRegenerating(true);
      await api.activities.regenerateActivity(activity.id, reason);
      await refreshPlannedActivities();
      setRegenerateSuccess(true);
      // Close modal after a short delay to show success message
      setTimeout(() => {
        setShowRegenerate(false);
        setRegenerateSuccess(false);
      }, 5000);
    } catch (error) {
      console.error('Failed to regenerate activity:', error);
      // You might want to show an error toast here
    } finally {
      setIsRegenerating(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-2 mb-4">
  <div className={`px-3 py-1 rounded-full text-sm font-medium ${
    activity.status === 'completed' 
      ? 'bg-green-100 text-green-700'
      : activity.status === 'skipped'
      ? 'bg-gray-100 text-gray-700' 
      : 'bg-orange-100 text-orange-700'
  }`}>
    {activity.status.charAt(0).toUpperCase() + activity.status.slice(1)}
  </div>
</div>

      {/* Overview Section */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="p-3 bg-gradient-to-br from-blue-400 to-blue-500 rounded-2xl shadow-sm">
            <span className="text-2xl">{typeConfig.icon}</span>
          </div>
          <div>
            <div className="font-semibold text-gray-900 text-lg">{typeConfig.title}</div>
            <div className="text-sm text-gray-500">
              {typeConfig.description}
            </div>
          </div>
        </div>
        <div className="text-right">
          <div className="text-3xl font-bold bg-gradient-to-r from-blue-500 to-blue-600 text-transparent bg-clip-text">
            {formatDistance(activity.blocks.reduce((total, block) => total + Number(block.distance), 0))} km
          </div>
          <div className="text-sm text-gray-500">
            {activity.targetPace}/km target pace
          </div>
        </div>
      </div>

      {/* Training Blocks */}
      {activity.blocks.length > 0 && (
        <div className="space-y-3">
          <div className="text-sm font-medium text-gray-500">Training Blocks</div>
          <div className="space-y-3 bg-gray-50/50 p-4 rounded-xl">
            {activity.blocks.map((block, index) => (
              <TrainingBlock 
                key={index}
                block={block}
                index={index}
                defaultPace={activity.targetPace}
              />
            ))}
          </div>
        </div>
      )}

      {/* Training Notes */}
      {activity.notes && (
        <div className="space-y-2">
          <div className="text-sm font-medium text-gray-500">Notes</div>
          <div className="bg-gray-50/50 backdrop-blur-sm rounded-xl p-4 text-gray-700 whitespace-pre-wrap">
            {activity.notes}
          </div>
        </div>
      )}

      {showRegenerate ? (
        <RegenerateFeedback
          onClose={() => setShowRegenerate(false)}
          onConfirm={handleRegenerate}
          isLoading={isRegenerating}
          isSuccess={regenerateSuccess}
        />
      ) : (
        <button
          onClick={() => setShowRegenerate(true)}
          className="w-full px-4 py-2 rounded-xl bg-orange-100 text-orange-700 hover:bg-orange-200"
        >
          Modify workout
        </button>
      )}
    </div>
  );
};

export default PlannedActivity;
