import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../context/ApiContext.tsx';
import CompletedActivity from '../components/Activities/components/CompletedActivity.tsx';
import PlannedActivity from '../components/Activities/components/PlannedActivity.tsx';
import thumbsUpCoach from '../assets/coach/thumbs_up.png';
import twoThumbsUpCoach from '../assets/coach/two_thumbs_up.png';
import runningCoach from '../assets/coach/running.png';
import SectionHeader from '../components/shared/SectionHeader.tsx';
import { api } from '../services/api.js';
import PageLayout from '../components/shared/PageLayout.tsx';
import ActivityBlocksGraph from '../components/Activities/components/ActivityBlocksGraph.tsx';
import { formatDistanceFromMeters, formatPace } from '../utils/activityUtils.ts';

const ActivityDetails = () => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);
  const { date } = useParams();
  const { activities, plannedActivities, refreshActivities, refreshPlannedActivities } = useApi();
  const [isPlannedOpen, setIsPlannedOpen] = useState(false);
  const [isCompletedOpen, setIsCompletedOpen] = useState(false);

  const pastActivity = activities?.find(a => {
    const activityDate = new Date(a.date).toISOString().split('T')[0];
    return activityDate === date;
  });

  const plannedActivity = plannedActivities?.find(a => a.date === date);

  const getCoachImage = (feedback) => {
    if (!feedback) return thumbsUpCoach;
    if (feedback.overallFeedback?.includes('Great')) return twoThumbsUpCoach;
    return runningCoach;
  };

  const handleSync = async () => {
    try {
      setIsSyncing(true);
      await api.activities.syncActivities();
      await refreshActivities();
      await refreshPlannedActivities();
      setIsSyncing(false);
    } catch (error) {
      setIsSyncing(false);
      console.error('Failed to sync activity:', error);
    }
  };

  const handleSkip = async () => {
    try {
      setIsSkipping(true);
      await api.activities.markActivitySkipped(plannedActivity.id);
      await refreshPlannedActivities();
      setIsSkipping(false);
    } catch (error) {
      setIsSkipping(false);
      console.error('Failed to mark activity as skipped:', error);
    }
  };

  // Calculate total distances
  const plannedDistance = plannedActivity?.blocks.reduce((total, block) => total + (block.distance * 1000), 0) || 0;
  const completedDistance = pastActivity?.blocks.reduce((total, block) => total + (block.distance * 1000), 0) || 0;

  // Get activity type and status
  const getActivityTypeAndStatus = () => {
    if (plannedActivity) {
      return {
        type: plannedActivity.type,
        status: plannedActivity.status,
        icon: plannedActivity.status === 'completed' ? 'bg-green-400/90' : 'bg-orange-400/90'
      };
    }
    if (pastActivity) {
      return {
        type: pastActivity.type,
        status: 'completed',
        icon: 'bg-green-400/90'
      };
    }
    return null;
  };

  const activityInfo = getActivityTypeAndStatus();

  return (
    <PageLayout 
      title={`Activity - ${new Date(date).toLocaleDateString()}`}
      showBack={true}
    >
      <div className="pb-[calc(3rem+env(safe-area-inset-bottom))] relative z-0">
        {!plannedActivity && !pastActivity && (
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-6 shadow-lg text-center">
            <div className="text-gray-500">No activities found for this date</div>
          </div>
        )}

        {(plannedActivity || pastActivity) && (
          <div className="space-y-6">
            {/* Quick Stats Overview */}
            <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-4 shadow-sm border border-gray-100">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className={`p-2 rounded-lg ${activityInfo?.icon}`}>
                    <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                      />
                    </svg>
                  </div>
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {activityInfo?.type.charAt(0).toUpperCase() + activityInfo?.type.slice(1)} Run
                    </div>
                    <div className="text-sm text-gray-500">
                      {activityInfo?.status.charAt(0).toUpperCase() + activityInfo?.status.slice(1)}
                    </div>
                  </div>
                </div>
                <div className="flex space-x-6">
                  {plannedActivity && (
                    <div className="text-right">
                      <div className="text-sm text-gray-500">Target Distance</div>
                      <div className="text-base font-semibold text-gray-900">
                        {formatDistanceFromMeters(plannedDistance)}
                      </div>
                    </div>
                  )}
                  {pastActivity && (
                    <div className="text-right">
                      <div className="text-sm text-gray-500">
                        {plannedActivity ? 'Completed' : 'Distance'}
                      </div>
                      <div className="text-base font-semibold text-gray-900">
                        {formatDistanceFromMeters(completedDistance)}
                      </div>
                    </div>
                  )}
                  {plannedActivity?.targetPace && (
                    <div className="text-right">
                      <div className="text-sm text-gray-500">Target Pace</div>
                      <div className="text-base font-semibold text-gray-900">
                        {formatPace(plannedActivity.targetPace)}
                      </div>
                    </div>
                  )}
                  {!plannedActivity && pastActivity?.averagePace && (
                    <div className="text-right">
                      <div className="text-sm text-gray-500">Average Pace</div>
                      <div className="text-base font-semibold text-gray-900">
                        {formatPace(pastActivity.averagePace)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Coach Feedback Section */}
            {plannedActivity?.feedback && (
              <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden border border-gray-100">
                <SectionHeader
                  title="Message from Ronny"
                  badge={{
                    text: plannedActivity.feedback.performance_rating,
                    color: 'purple'
                  }}
                  action={
                    <div className="bg-white p-1 rounded-full shadow-md">
                      <img 
                        src={getCoachImage(plannedActivity.feedback)} 
                        alt="Ronny" 
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                  }
                />
                
                <div className="p-6 space-y-6">
                  {/* Main Message */}
                  {plannedActivity.feedback.message && (
                    <div className="flex items-start space-x-4">
                      <div className="flex-1">
                        <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-4 rounded-xl shadow-sm">
                          <p className="text-gray-800">{plannedActivity.feedback.message}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {/* Tip Section */}
                  {plannedActivity.feedback.tip && (
                    <div className="bg-yellow-50/50 rounded-xl p-4">
                      <div className="flex items-center space-x-2 mb-2">
                        <svg className="w-5 h-5 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" 
                          />
                        </svg>
                        <h3 className="text-sm font-medium text-gray-900">Pro Tip</h3>
                      </div>
                      <p className="text-sm text-gray-600">{plannedActivity.feedback.tip}</p>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden border border-gray-100">
              <SectionHeader
                title="Activity Overview"
              />
              <div className="p-6">
                <ActivityBlocksGraph 
                  plannedBlocks={plannedActivity?.blocks}
                  completedBlocks={pastActivity?.blocks}
                />
              </div>
            </div>
            
            {/* Activities */}
            <div className="space-y-6">
               {plannedActivity && plannedActivity.status === 'planned' && new Date(date) <= new Date() && (
                <div className="mb-6">
                  <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-4 shadow-sm border border-gray-100">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <div className={`p-2 rounded-lg bg-blue-400/90`}>
                            <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
                              />
                            </svg>
                        </div>
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                          'Sync with Strava'
                          </div>
                          <div className="text-sm text-gray-500">
                          'Import your activity from Strava'
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={handleSkip}
                          disabled={isSkipping}
                          className={`px-4 py-2 rounded-lg text-sm font-medium text-white 
                            ${isSkipping 
                              ? 'bg-gray-400 cursor-not-allowed' 
                              : 'bg-gray-500 hover:bg-gray-600'
                            }`}
                        >
                          {isSkipping ? 'Marking as skipped...' : 'Mark as Skipped'}
                        </button>
                        <button
                          onClick={handleSync}
                          disabled={isSyncing}
                          className={`px-4 py-2 rounded-lg text-sm font-medium text-white 
                            ${isSyncing 
                              ? 'bg-gray-400 cursor-not-allowed' 
                              : 'bg-blue-500 hover:bg-blue-600'
                            }`}
                        >
                          {isSyncing ? 'Syncing...' : 'Sync Now'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Activities Comparison */}
              {plannedActivity && pastActivity ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden border border-gray-100">
                    <SectionHeader
                      title="Planned Activity"
                      badge={{
                        text: plannedActivity.type,
                        color: 'blue'
                      }}
                      collapsible
                      defaultOpen={isPlannedOpen}
                      onToggle={setIsPlannedOpen}
                    />
                    <div className={`transition-all duration-200 ${
                      isPlannedOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                    }`}>
                      <div className="p-6">
                        <PlannedActivity activity={plannedActivity} />
                      </div>
                    </div>
                  </div>

                  <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden border border-gray-100">
                    <SectionHeader
                      title="Completed Activity"
                      badge={{
                        text: pastActivity.type,
                        color: 'green'
                      }}
                      collapsible
                      defaultOpen={isCompletedOpen}
                      onToggle={setIsCompletedOpen}
                    />
                    <div className={`transition-all duration-200 ${
                      isCompletedOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                    }`}>
                      <div className="p-6">
                        <CompletedActivity activity={pastActivity} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // Single activity view (either planned or completed)
                <>
                  {plannedActivity && (
                    <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden border border-gray-100">
                      <SectionHeader
                        title="Planned Activity"
                        badge={{
                          text: plannedActivity.type,
                          color: 'blue'
                        }}
                        collapsible
                        defaultOpen={isPlannedOpen}
                        onToggle={setIsPlannedOpen}
                      />
                      <div className={`transition-all duration-200 ${
                        isPlannedOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                      }`}>
                        <div className="p-6">
                          <PlannedActivity activity={plannedActivity} />
                        </div>
                      </div>
                    </div>
                  )}

                  {pastActivity && (
                    <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden border border-gray-100">
                      <SectionHeader
                        title="Completed Activity"
                        badge={{
                          text: pastActivity.type,
                          color: 'green'
                        }}
                        collapsible
                        defaultOpen={isCompletedOpen}
                        onToggle={setIsCompletedOpen}
                      />
                      <div className={`transition-all duration-200 ${
                        isCompletedOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                      }`}>
                        <div className="p-6">
                          <CompletedActivity activity={pastActivity} />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default ActivityDetails;