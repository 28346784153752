import React, { useState } from 'react';
import { useApi } from '../context/ApiContext.tsx';
import PageLayout from '../components/shared/PageLayout.tsx';
import TrainingCard from '../components/Training/TrainingCard.tsx';
import { formatDistanceFromMeters } from '../utils/activityUtils.ts';
import { api } from '../services/api';
import thumbsUpCoach from '../assets/coach/thumbs_up.png';
import twoThumbsUpCoach from '../assets/coach/two_thumbs_up.png';
import runningCoach from '../assets/coach/running.png';
import SectionHeader from '../components/shared/SectionHeader.tsx';

const WeekOverview = () => {
  const { plannedActivities, weekPlan, refreshWeekPlan } = useApi();
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedWeekOffset, setSelectedWeekOffset] = useState(0);

  // Get week dates based on offset
  const getWeekDates = (offset: number) => {
    const today = new Date();
    const monday = new Date(today);
    const daysSinceMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
    
    monday.setDate(today.getDate() - daysSinceMonday + (offset * 7));
    monday.setHours(0, 0, 0, 0);

    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    sunday.setHours(23, 59, 59, 999);

    return { monday, sunday };
  };

  const formatWeekRange = (start: Date, end: Date) => {
    const formatDate = (date: Date) => {
      return date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric'
      });
    };
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  // Get activities for selected week
  const { monday, sunday } = getWeekDates(selectedWeekOffset);
  // Get current week's activities (reusing logic from Dashboard)
  const thisWeekPlanned = plannedActivities?.filter(activity => {
    const activityDate = new Date(activity.date);

    return activityDate >= monday && activityDate <= sunday;
  });

  // Get current week number and year
  const today = new Date();

  const currentWeekNumber = Math.ceil(
    ((today.getTime() - new Date(today.getFullYear(), 0, 1).getTime()) / 86400000 + 
    new Date(today.getFullYear(), 0, 1).getDay() + 1) / 7
  );
  
  const currentWeek = weekPlan?.find(week => 
    week.week_number === currentWeekNumber + selectedWeekOffset && 
    week.year === today.getFullYear()
  );

  const completedCount = thisWeekPlanned?.filter(a => a.status === 'completed').length || 0;
  const skippedCount = thisWeekPlanned?.filter(a => a.status === 'skipped').length || 0;
  const totalDistance = thisWeekPlanned?.reduce((total, activity) => {
    if (activity.status === 'completed') {
      return total + activity.totalDistance;
    } else if (activity.status === 'planned') {
      return total + (activity.blocks?.reduce((blockTotal, block) => 
        blockTotal + (block.distance * 1000), 0) || 0);
    }
    return total;
  }, 0) || 0;
  

    // Check if selected week is current or past week
    const isCurrentOrPastWeek = sunday <= new Date();

    // Updated isWeekCompleted to include the week timing check
    const isWeekCompleted = isCurrentOrPastWeek && thisWeekPlanned?.every(
      activity => activity.status === 'completed' || activity.status === 'skipped'
    );

  const handleSubmitFeedback = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentWeek?.id || !feedback.trim()){
        console.log(currentWeek);
        console.log(feedback);
        console.log('No current week or feedback');
        return;
    }

    try {
      setIsSubmitting(true);
      await api.weekPlan.submitWeekFeedback(currentWeek.id, feedback);
      await refreshWeekPlan();
      setFeedback('');
    } catch (error) {
      console.error('Failed to submit feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getCoachImage = (feedback) => {
    if (!feedback) return thumbsUpCoach;
    if (feedback.message?.includes('Great')) return twoThumbsUpCoach;
    return runningCoach;
  };

  return (
    <PageLayout title="This Week">
      {/* Week Navigation */}
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={() => setSelectedWeekOffset(prev => prev - 1)}
          className="p-2 text-gray-600 hover:text-orange-600 transition-colors"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        
        <div className="text-center">
          <div className="text-sm font-medium text-gray-600">
            {formatWeekRange(monday, sunday)}
          </div>
          {selectedWeekOffset === 0 && (
            <div className="text-xs text-orange-600">Current Week</div>
          )}
        </div>

        <button
          onClick={() => setSelectedWeekOffset(prev => prev + 1)}
          className="p-2 text-gray-600 hover:text-orange-600 transition-colors"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>

      <div className="space-y-6 pb-24">
        {/* Weekly Stats */}
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-white rounded-2xl p-4 shadow-sm border border-orange-100">
            <div className="text-sm text-gray-600">Completed</div>
            <div className="text-2xl font-bold text-green-600">{completedCount}</div>
          </div>
          <div className="bg-white rounded-2xl p-4 shadow-sm border border-orange-100">
            <div className="text-sm text-gray-600">Skipped</div>
            <div className="text-2xl font-bold text-gray-600">{skippedCount}</div>
          </div>
          <div className="bg-white rounded-2xl p-4 shadow-sm border border-orange-100">
            <div className="text-sm text-gray-600">Distance</div>
            <div className="text-2xl font-bold text-orange-600">
              {formatDistanceFromMeters(totalDistance)}
            </div>
          </div>
        </div>

        {/* Week Feedback section */}
        {isWeekCompleted && !currentWeek?.feedback && (
          <div className="bg-white rounded-2xl shadow-sm border border-orange-100 p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Week Feedback</h2>
            <form onSubmit={handleSubmitFeedback} className="space-y-4">
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="How did this week go? What went well? What could be improved?"
                className="w-full h-32 rounded-xl border-gray-200 bg-white/50 placeholder:text-gray-400 p-4"
                required
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full px-4 py-2 rounded-xl text-white font-medium
                  ${isSubmitting 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-orange-500 hover:bg-orange-600'
                  }`}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
              </button>
            </form>
          </div>
        )}

        {/* Show feedback if exists */}
        {(currentWeek?.feedback || currentWeek?.['coach-feedback']) && (
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden border border-gray-100">
            <SectionHeader
              title="Week Review"
              badge={{
                text: currentWeek['coach-feedback']?.performance_rating || 'Feedback',
                color: 'purple'
              }}
              action={
                currentWeek['coach-feedback'] && (
                  <div className="bg-white p-1 rounded-full shadow-md">
                    <img 
                      src={getCoachImage(currentWeek['coach-feedback'])} 
                      alt="Ronny" 
                      className="w-10 h-10 rounded-full"
                    />
                  </div>
                )
              }
            />
            
            <div className="p-6 space-y-6">
              {/* User Feedback */}
              {currentWeek.feedback && (
                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-700">Your Feedback</h3>
                  <div className="bg-orange-50/50 rounded-xl p-4">
                    <p className="text-gray-700">{currentWeek.feedback}</p>
                  </div>
                </div>
              )}

              {/* Coach Feedback */}
              {currentWeek['coach-feedback'] && (
                <div className="space-y-4">
                  {/* Main Message */}
                  <div className="flex items-start space-x-4">
                    <div className="flex-1">
                      <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-4 rounded-xl shadow-sm">
                        <p className="text-gray-800">{currentWeek['coach-feedback'].message}</p>
                      </div>
                    </div>
                  </div>
                  
                  {/* Tip Section */}
                  {currentWeek['coach-feedback'].tip && (
                    <div className="bg-yellow-50/50 rounded-xl p-4">
                      <div className="flex items-center space-x-2 mb-2">
                        <svg className="w-5 h-5 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" 
                          />
                        </svg>
                        <h3 className="text-sm font-medium text-gray-900">Pro Tip</h3>
                      </div>
                      <p className="text-sm text-gray-600">{currentWeek['coach-feedback'].tip}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Activities List */}
        <div className="space-y-4">
          {thisWeekPlanned?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            .map((activity) => (
              <TrainingCard 
                key={activity.id}
                training={activity}
              />
            ))}
        </div>
      </div>
    </PageLayout>
  );
};

export default WeekOverview;