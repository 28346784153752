import React, { useState, useEffect } from 'react';

interface Props {
  onSubmit: (goal: Goal) => void;
  isOnboarding?: boolean;
  isSubmitting?: boolean;
}

interface GoalTemplate {
  icon: string;
  title: string;
  subtitle: string;
  distance: number;
  targetPace: string;
}

const goalTemplates: GoalTemplate[] = [
  { 
    icon: '🏃‍♂️', 
    title: '5K Race', 
    subtitle: 'Great for beginners',
    distance: 5,
    targetPace: '06:00'
  },
  { 
    icon: '🏃‍♀️', 
    title: '10K Race', 
    subtitle: 'Challenge yourself',
    distance: 10,
    targetPace: '06:15'
  },
  { 
    icon: '🏃‍♂️', 
    title: 'Half Marathon', 
    subtitle: 'A challenging goal',
    distance: 21.1,
    targetPace: '06:30'
  },
  { 
    icon: '🏃‍♀️', 
    title: 'Marathon', 
    subtitle: 'The ultimate challenge',
    distance: 42.2,
    targetPace: '07:00'
  }
];

const GoalForm: React.FC<Props> = ({ onSubmit, isOnboarding, isSubmitting = false }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    distance: '',
    targetPace: '',
    targetTime: '',
    date: ''
  });

  // Helper functions for time calculations
  const timeToSeconds = (timeStr: string): number => {
    if (!timeStr) return 0;
    const parts = timeStr.split(':').map(Number);
    if (parts.length === 2) { // MM:SS format
      return parts[0] * 60 + parts[1];
    }
    if (parts.length === 3) { // HH:MM:SS format
      return parts[0] * 3600 + parts[1] * 60 + parts[2];
    }
    return 0;
  };

  const formatTimeString = (hours: number, minutes: number, seconds: number): string => {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatPaceString = (minutes: number, seconds: number): string => {
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Update calculations when distance, pace, or time changes
  useEffect(() => {
    const distance = parseFloat(formData.distance);
    if (!distance) return;

    if (formData.targetPace && !formData.targetTime) {
      // Calculate total time from pace and distance
      const paceSeconds = timeToSeconds(formData.targetPace);
      const totalSeconds = paceSeconds * distance;
      
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);

      setFormData(prev => ({
        ...prev,
        targetTime: formatTimeString(hours, minutes, seconds)
      }));
    }
    else if (formData.targetTime && !formData.targetPace) {
      // Calculate pace from total time and distance
      const totalSeconds = timeToSeconds(formData.targetTime);
      const paceSeconds = totalSeconds / distance;
      
      const minutes = Math.floor(paceSeconds / 60);
      const seconds = Math.floor(paceSeconds % 60);

      setFormData(prev => ({
        ...prev,
        targetPace: formatPaceString(minutes, seconds)
      }));
    }
  }, [formData.distance, formData.targetPace, formData.targetTime]);

  const handlePaceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    // Only allow numbers and colon
    if (!/^[\d:]*$/.test(value)) return;
    
    // Format as MM:SS if possible
    if (value.length === 4 && !value.includes(':')) {
      const minutes = value.slice(0, 2);
      const seconds = value.slice(2, 4);
      setFormData(prev => ({
        ...prev,
        targetPace: `${minutes}:${seconds}`,
        targetTime: '' // Clear time to trigger recalculation
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        targetPace: value,
        targetTime: '' // Clear time to trigger recalculation
      }));
    }
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    // Only allow numbers and colon
    if (!/^[\d:]*$/.test(value)) return;
    
    // Format as HH:MM:SS if possible
    if (value.length === 6 && !value.includes(':')) {
      const hours = value.slice(0, 2);
      const minutes = value.slice(2, 4);
      const seconds = value.slice(4, 6);
      setFormData(prev => ({
        ...prev,
        targetTime: `${hours}:${minutes}:${seconds}`,
        targetPace: '' // Clear pace to trigger recalculation
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        targetTime: value,
        targetPace: '' // Clear pace to trigger recalculation
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      name: formData.name,
      description: formData.description,
      distance: parseFloat(formData.distance),
      targetPace: formData.targetPace,
      targetTime: formData.targetTime,
      date: formData.date
    });
  };

  const handleTemplateSelect = (template: GoalTemplate) => {
    setFormData({
      ...formData,
      name: template.title,
      description: template.subtitle,
      distance: template.distance.toString(),
      targetPace: template.targetPace,
      targetTime: '' // Will be calculated automatically
    });
  };

  return (
    <div className="space-y-6">
      {/* Quick Templates - Only show in onboarding */}
      {isOnboarding && (
        <div className="grid grid-cols-1 gap-3 mb-8">
          {goalTemplates.map((template) => (
            <button
              key={template.title}
              type="button"
              onClick={() => handleTemplateSelect(template)}
              className={`w-full p-4 rounded-xl border transition-colors text-left
                ${formData.name === template.title 
                  ? 'border-blue-500 bg-blue-50' 
                  : 'border-gray-200 hover:border-blue-500 hover:bg-blue-50'
                }
              `}
            >
              <div className="flex items-center space-x-4">
                <span className="text-2xl">{template.icon}</span>
                <div>
                  <div className="font-medium text-gray-900">{template.title}</div>
                  <div className="text-sm text-gray-500">{template.subtitle}</div>
                </div>
              </div>
            </button>
          ))}
        </div>
      )}

      {/* Keep your existing form JSX, but remove the description field if isOnboarding is true */}
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name Input */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
            Goal Name
          </label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            placeholder="e.g., Complete a 10K Race"
            required
          />
        </div>

        {/* Description - Only show if not onboarding */}
        {!isOnboarding && (
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-2">
              Description
            </label>
            <textarea
              id="description"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="Describe your goal..."
              rows={3}
            />
          </div>
        )}

        {/* Distance and Pace Group */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label htmlFor="distance" className="block text-sm font-medium text-gray-700 mb-2">
              Target Distance (km)
            </label>
            <input
              type="number"
              id="distance"
              value={formData.distance}
              onChange={(e) => setFormData(prev => ({ ...prev, distance: e.target.value }))}
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="5"
              step="0.1"
              min="0"
              required
            />
          </div>

          <div>
            <label htmlFor="targetPace" className="block text-sm font-medium text-gray-700 mb-2">
              Target Pace (min/km)
            </label>
            <input
              type="text"
              id="targetPace"
              value={formData.targetPace}
              onChange={handlePaceChange}
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="5:30"
            />
          </div>
        </div>

        {/* Target Time and Deadline Group */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label htmlFor="targetTime" className="block text-sm font-medium text-gray-700 mb-2">
              Target Time
            </label>
            <input
              type="text"
              id="targetTime"
              value={formData.targetTime}
              onChange={handleTimeChange}
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              placeholder="00:30:00"
            />
          </div>

          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-2">
              Target Date
            </label>
            <input
              type="date"
              id="date"
              value={formData.date}
              onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
              className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              required
            />
          </div>
        </div>

        {/* Update the submit button text based on context */}
        <button
          type="submit"
          disabled={isSubmitting}
          className={`
            w-full mt-6 py-3.5 px-4 rounded-xl font-medium 
            text-white transition-all duration-200 
            shadow-sm hover:shadow flex items-center justify-center space-x-2
            ${isSubmitting 
              ? 'bg-gray-400 cursor-not-allowed' 
              : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700'
            }
          `}
        >
          {isSubmitting ? (
            <>
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>{isOnboarding ? 'Saving...' : 'Creating Goal...'}</span>
            </>
          ) : (
            <>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <span>{isOnboarding ? 'Continue' : 'Create Goal'}</span>
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default GoalForm;