import React, { useState } from 'react';
import ronnyThinking from '../../../assets/coach/stretching.png';

interface RegenerateFeedbackProps {
  onClose: () => void;
  onConfirm: (reason: string) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
}

const RegenerateFeedback: React.FC<RegenerateFeedbackProps> = ({
  onClose,
  onConfirm,
  isLoading = false,
  isSuccess = false,
}) => {
  const [reason, setReason] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setHasSubmitted(true);
    onConfirm(reason);
  };

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg border border-gray-100 overflow-hidden">
      <div className="p-6 space-y-4">
        {/* Initial Message */}
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <img 
              src={ronnyThinking} 
              alt="Ronny thinking" 
              className="w-12 h-12 rounded-full bg-orange-100 p-1"
            />
          </div>
          <div className="flex-1">
            <div className="bg-orange-50 p-4 rounded-xl">
              <p className="font-medium mb-1">Hey there! 👋</p>
              <p className="text-gray-700">
                I notice you'd like to change this workout. Could you tell me why?
              </p>
            </div>
          </div>
        </div>

        {/* Form or Submitted Message */}
        {!hasSubmitted ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="ml-16">
              <textarea
                rows={3}
                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
                placeholder="Tell me why you'd like to change this workout..."
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>
            
            <div className="flex gap-3 justify-end">
              <button
                type="button"
                onClick={onClose}
                disabled={isLoading}
                className="px-4 py-2 rounded-xl bg-white border border-gray-200 text-gray-700 hover:bg-gray-50 disabled:opacity-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading || !reason.trim()}
                className="px-4 py-2 rounded-xl bg-orange-500 text-white hover:bg-orange-600 disabled:opacity-50"
              >
                Send Feedback
              </button>
            </div>
          </form>
        ) : (
          <>
            {/* User's Message */}
            <div className="flex items-start justify-end space-x-4">
              <div className="flex-1 flex justify-end">
                <div className="bg-orange-500 text-white p-4 rounded-xl max-w-[80%]">
                  <p>{reason}</p>
                </div>
              </div>
            </div>

            {/* Ronny's Response */}
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <img 
                  src={ronnyThinking} 
                  alt="Ronny thinking" 
                  className="w-12 h-12 rounded-full bg-orange-100 p-1"
                />
              </div>
              <div className="flex-1">
                <div className="bg-orange-50 p-4 rounded-xl">
                  {isLoading ? (
                    <div className="flex items-center space-x-2">
                      <p className="font-medium">Working on it</p>
                      <div className="flex space-x-1">
                        <div className="w-2 h-2 bg-orange-500 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                        <div className="w-2 h-2 bg-orange-500 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                        <div className="w-2 h-2 bg-orange-500 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                      </div>
                    </div>
                  ) : isSuccess ? (
                    <>
                      <p className="font-medium mb-1">All set! 🎉</p>
                      <p className="text-gray-700">
                        I've updated your workout based on your feedback.
                      </p>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RegenerateFeedback; 