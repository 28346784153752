import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Goal, useApi } from '../../context/ApiContext.tsx';
import GoalForm from '../Goals/GoalForm.tsx';
import DaySelector from '../shared/DaySelector.tsx';
import twoThumbsUpCoach from '../../assets/coach/two_thumbs_up.png';

const OnboardingFlow: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<OnboardingStep>('welcome');
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { refreshUserDetails, postGoal, updateTrainingDays, trainingDays } = useApi();
  const navigate = useNavigate();

  // Prefill days from user data
  useEffect(() => {
    if (trainingDays) {
      setSelectedDays(trainingDays);
    }
  }, [trainingDays]);

  const handleGoalSubmit = async (goal: Goal) => {
    try {
      setIsSubmitting(true);
      await postGoal(goal);
      setCurrentStep('schedule');
    } catch (error) {
      console.error('Error creating goal:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDayToggle = (dayValue: number) => {
    setSelectedDays(prev => 
      prev.includes(dayValue)
        ? prev.filter(d => d !== dayValue)
        : [...prev, dayValue]
    );
  };

  const handleComplete = async () => {
    try {
      setIsSubmitting(true);
      if (selectedDays.length > 0) {
        const sortedDays = [...selectedDays].sort((a, b) => a - b);
        await updateTrainingDays(sortedDays);
      }
      await refreshUserDetails();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error during onboarding:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-white/95 backdrop-blur-sm z-50">
      <div className="container mx-auto max-w-lg px-4 py-8">
        {currentStep === 'welcome' && (
          <div className="space-y-6 text-center">
            <div className="w-32 h-32 mx-auto">
              <img 
                src={twoThumbsUpCoach} 
                alt="Ronny thumbs up"
                className="w-full h-full object-contain animate-bounce-gentle"
              />
            </div>
            <h1 className="text-2xl font-bold text-gray-900">
              Meet Ronny, Your Running Buddy!
            </h1>
            <p className="text-gray-600">
              Hi! I'm Ronny, and I'll be your personal running coach. 
              Let's set up your training plan together!
            </p>
            <div className="pt-4">
              <button
                onClick={() => setCurrentStep('goals')}
                className="w-full py-3 px-4 bg-blue-600 text-white rounded-xl font-medium
                  hover:bg-blue-700 transition-colors shadow-sm"
              >
                Let's Get Started
              </button>
            </div>
          </div>
        )}

        {currentStep === 'goals' && (
          <div className="space-y-6">
            <div className="text-center">
              <div className="text-4xl mb-6">🎯</div>
              <h2 className="text-xl font-bold text-gray-900 mb-2">What's Your Goal?</h2>
              <p className="text-gray-600 text-sm">Choose your primary running goal</p>
            </div>

            <GoalForm 
              onSubmit={handleGoalSubmit} 
              isOnboarding={true}
              isSubmitting={isSubmitting}
            />
          </div>
        )}

        {currentStep === 'schedule' && (
          <div className="space-y-6">
            <div className="text-center">
              <div className="text-4xl mb-6">📅</div>
              <h2 className="text-xl font-bold text-gray-900 mb-2">When Do You Train?</h2>
              <p className="text-gray-600 text-sm">Select your preferred training days</p>
            </div>

            <DaySelector
              selectedDays={selectedDays}
              onDayToggle={handleDayToggle}
              isSubmitting={isSubmitting}
            />

            <div className="pt-6">
              <button
                onClick={handleComplete}
                disabled={selectedDays.length === 0 || isSubmitting}
                className="w-full py-3 px-4 bg-blue-600 text-white rounded-xl font-medium
                  hover:bg-blue-700 transition-colors shadow-sm disabled:opacity-50 
                  disabled:cursor-not-allowed"
              >
                Complete Setup
              </button>
            </div>
          </div>
        )}

        {/* Progress Indicator */}
        <div className="fixed bottom-8 left-0 right-0 flex justify-center space-x-2">
          {['welcome', 'goals', 'schedule'].map((step) => (
            <div
              key={step}
              className={`w-2 h-2 rounded-full ${
                currentStep === step ? 'bg-blue-600' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnboardingFlow; 