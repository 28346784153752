import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-white flex items-center justify-center">
      <div className="space-y-4 text-center">
        <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-600 
          rounded-full animate-spin mx-auto"
        />
        <div className="text-gray-500 font-medium animate-pulse">
          Loading your training data...
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen; 