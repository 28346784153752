import React from 'react';
import stretchingRonny from '../../assets/coach/stretching.png';

const Login = ({ onLogin }) => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-orange-500 to-orange-400 p-6">
      {/* Logo and Mascot Container */}
      <div className="w-full max-w-md flex flex-col items-center mb-8">
        <div className="relative w-40 h-40 mb-2">
          <img 
            src={stretchingRonny}
            alt="Ronny Stretching" 
            className="w-full h-full object-contain drop-shadow-xl animate-stretch"
            style={{
              transformOrigin: 'bottom center',
              animation: 'stretch 3s ease-in-out infinite'
            }}
          />
        </div>
        <h1 className="text-4xl font-extrabold text-white mt-2 tracking-tight animate-fade-in">
          Go<span className="text-orange-800">Ronny</span>
        </h1>
        <p className="text-white/90 mt-2 text-center text-lg font-medium animate-fade-in-delay">
          Your friendly running companion! 🏃‍♂️
        </p>
      </div>

      {/* Login Card */}
      <div className="bg-white w-full max-w-md rounded-3xl shadow-2xl overflow-hidden animate-float">
        <div className="p-8">
          <h2 className="text-xl font-bold text-gray-800 text-center mb-2">
            Ready to run with Ronny?
          </h2>
          <p className="text-gray-600 mb-8 text-center">
            Connect with Strava to start your journey
          </p>

          {/* Strava Button */}
          <button
            onClick={onLogin}
            className="w-full flex items-center justify-center gap-3 bg-[#fc4c02] text-white py-4 px-6 rounded-2xl
                     hover:bg-[#e34400] transition-all duration-200 font-semibold text-lg
                     transform hover:scale-[1.02] active:scale-[0.98] shadow-lg
                     animate-pulse-subtle"
          >
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M15.387 17.944l-2.089-4.116h-3.065L15.387 24l5.15-10.172h-3.066l-2.084 4.116z" />
              <path d="M10.322 8.691L15.387 0 20.45 8.691h-3.066L15.387 4.58l-2.089 4.111h-2.976z" />
            </svg>
            Connect with Strava
          </button>

          {/* Fun Footer Message */}
          <p className="text-gray-500 text-sm text-center mt-6 animate-fade-in-delay-2">
            Join thousands of runners having fun with Ronny! 🎉
          </p>
        </div>
      </div>

      {/* Terms and Privacy */}
      <p className="text-white/80 text-sm mt-8 text-center animate-fade-in-delay-2">
        By continuing, you agree to our{' '}
        <a href="/terms" className="underline hover:text-white transition-colors">Terms</a> and{' '}
        <a href="/privacy" className="underline hover:text-white transition-colors">Privacy Policy</a>
      </p>
    </div>
  );
};

export default Login;
