import React from 'react';
import { TrainingBlockType } from '../../../types/activity';
import { getCompletedBlockType, formatPace, formatDistance } from '../../../utils/activityUtils.ts';

interface Block {
  type?: TrainingBlockType;
  distance: number;
  pace: number;
}

interface ActivityBlocksGraphProps {
  plannedBlocks?: Block[];
  completedBlocks?: Block[];
}

const blockStyles: Record<TrainingBlockType, { color: string, label: string, textColor: string }> = {
  warmup: { color: '#60A5FA', label: 'Warm-up', textColor: 'text-black' },
  interval: { color: '#A78BFA', label: 'Interval', textColor: 'text-black' },
  cooldown: { color: '#34D399', label: 'Cool-down', textColor: 'text-black' },
  recovery: { color: '#FBBF24', label: 'Recovery', textColor: 'text-black' },
  main: { color: '#FB923C', label: 'Main', textColor: 'text-black' },
};

const ActivityBlocksGraph: React.FC<ActivityBlocksGraphProps> = ({
  plannedBlocks,
  completedBlocks,
}) => {
  const sourceBlocks = plannedBlocks || completedBlocks || [];
  const isSingleBlock = sourceBlocks.length === 1;

  // For single blocks, use fixed width instead of pace-based width
  const getPaceWidth = (pace: number) => {
    if (isSingleBlock) return 100; // Full width for single blocks

    const fastestPace = Math.min(
      ...(plannedBlocks || []).map(b => b.pace),
      ...(completedBlocks || []).map(b => b.pace)
    );
    
    const slowestPace = Math.max(
      ...(plannedBlocks || []).map(b => b.pace),
      ...(completedBlocks || []).map(b => b.pace)
    );

    const paceRange = slowestPace - fastestPace;
    // Scale from 35% to 100%
    return 35 + (((slowestPace - pace) / paceRange) * 65);
  };

  const renderBlockPair = (index: number) => {
    const planned = plannedBlocks?.[index];
    const completed = completedBlocks?.[index];
    const type = planned?.type || (completed && getCompletedBlockType(completed, index.toString()));
    const style = blockStyles[type];

    const label = isSingleBlock ? 'Activity': style?.label;

    return (
      <div key={index} className="mb-3">
        <div className="flex items-center justify-between mb-1">
          <span className="text-xs font-medium text-gray-600">
            {label}
          </span>
        </div>

        <div className="space-y-1">
          {/* Planned Block */}
          {planned && (
            <div className="flex items-center space-x-2">
              <div className="w-12 text-xs text-gray-400">
                {isSingleBlock ? 'Target' : 'Plan'}
              </div>
              <div className="flex-1 relative h-7">
                {/* Background with opacity */}
                <div 
                  className="absolute left-0 top-0 h-full rounded-md transition-all duration-200"
                  style={{ 
                    width: `${getPaceWidth(planned.pace)}%`,
                    backgroundColor: style?.color,
                    opacity: isSingleBlock ? 0.15 : 0.3
                  }}
                />
                {/* Text overlay without opacity */}
                <div 
                  className="absolute left-0 top-0 h-full rounded-md transition-all duration-200"
                  style={{ 
                    width: `${getPaceWidth(planned.pace)}%`,
                  }}
                >
                  <div className="absolute inset-0 flex items-center justify-between px-2">
                    <span className={`text-xs font-medium ${style?.textColor}`}>
                      {formatDistance(planned.distance)}km
                    </span>
                    <span className={`text-xs font-medium ${style?.textColor}`}>
                      {formatPace(planned.pace)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Completed Block */}
          {completed && (
            <div className="flex items-center space-x-2">
              <div className="w-12 text-xs text-gray-400">
                {isSingleBlock ? 'Actual' : 'Done'}
              </div>
              <div className="flex-1 relative h-7">
                {/* Background */}
                <div 
                  className="absolute left-0 top-0 h-full rounded-md transition-all duration-200"
                  style={{ 
                    width: `${getPaceWidth(completed.pace)}%`,
                    backgroundColor: style?.color
                  }}
                />
                {/* Text overlay */}
                <div 
                  className="absolute left-0 top-0 h-full rounded-md transition-all duration-200"
                  style={{ 
                    width: `${getPaceWidth(completed.pace)}%`,
                  }}
                >
                  <div className="absolute inset-0 flex items-center justify-between px-2">
                    <span className={`text-xs font-medium ${style?.textColor}`}>
                      {formatDistance(completed.distance)}km
                    </span>
                    <span className={`text-xs font-medium ${style?.textColor}`}>
                      {formatPace(completed.pace)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <div>
        {sourceBlocks.map((_, index) => renderBlockPair(index))}
      </div>
    </div>
  );
};

export default ActivityBlocksGraph;