import React from 'react';
import { useApi } from '../../context/ApiContext.tsx';
import CoachMessage from './components/CoachMessage.tsx';
import TrainingCalendar from './components/TrainingCalendar.tsx';
import NextTraining from './components/NextTraining.tsx';
import { formatDistanceFromMeters } from '../../utils/activityUtils.ts';
import { CompletedActivity, PlannedActivity } from '../../types/activity';
import GoalList from '../Goals/GoalList.tsx';
import PageLayout from '../shared/PageLayout.tsx';
import LoadingScreen from '../shared/LoadingScreen.tsx';
import ErrorScreen from '../shared/ErrorScreen.tsx';
import OnboardingFlow from '../Onboarding/OnboardingFlow.tsx';

const Dashboard: React.FC = () => {
  const { 
    loading, 
    error, 
    userDetails, 
    activities,
    plannedActivities,
  } = useApi();

  if (loading) return <LoadingScreen />;
  if (error) {
    return (
      <ErrorScreen 
        error={error}
      />
    );
  }
  if (!userDetails.hasCompletedSetup) return <OnboardingFlow />;

  // Get the current week's activities (Monday to Sunday)
  const thisWeekActivities = (activities as CompletedActivity[])
    .filter(activity => {
      const activityDate = new Date(activity.startTime);
      const today = new Date();
      
      // Get Monday of current week
      const monday = new Date(today);
      const daysSinceMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
      monday.setDate(today.getDate() - daysSinceMonday);
      monday.setHours(0, 0, 0, 0);

      return activityDate >= monday;
    });

  // Get planned activities for current week
  const thisWeekPlanned = (plannedActivities as PlannedActivity[])
    .filter(activity => {
      const activityDate = new Date(activity.date);
      const today = new Date();
      
      // Get Monday and Sunday of current week
      const monday = new Date(today);
      const daysSinceMonday = today.getDay() === 0 ? 6 : today.getDay() - 1;
      monday.setDate(today.getDate() - daysSinceMonday);
      monday.setHours(0, 0, 0, 0);

      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6);
      sunday.setHours(23, 59, 59, 999);

      return activityDate >= monday && activityDate <= sunday;
    });


  const thisWeekDistance = thisWeekActivities
    .reduce((total, activity) => total + activity.totalDistance, 0);

  // Get the next upcoming training
  const getNextTraining = () => {
    if (!plannedActivities?.length) return undefined;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return (plannedActivities as PlannedActivity[])
      .filter(activity => {
        const activityDate = new Date(activity.date);
        activityDate.setHours(0, 0, 0, 0);
        return activityDate >= today;
      })
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];
  };

  const nextTraining = getNextTraining();

  return (
    <PageLayout>
      {/* Coach Message */}
      <div className="bg-gradient-to-r from-orange-500 to-orange-400 rounded-2xl shadow-xl overflow-hidden">
        <CoachMessage 
          user={userDetails}
          plannedActivities={plannedActivities}
        />
      </div>

      {/* Quick Stats */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <div className="bg-white rounded-2xl shadow-sm border border-orange-100 p-6 hover:shadow-md transition-shadow">
          <div className="text-sm font-medium text-gray-600">Weekly Distance</div>
          <div className="mt-2 flex items-baseline">
            <div className="text-2xl sm:text-3xl font-bold text-orange-600">
              {formatDistanceFromMeters(thisWeekDistance)}
            </div>
            <div className="ml-2 text-sm text-orange-600">km</div>
          </div>
        </div>

        <div className="bg-white rounded-2xl shadow-sm border border-orange-100 p-6 hover:shadow-md transition-shadow">
          <div className="text-sm font-medium text-gray-600">Week's Activities</div>
          <div className="mt-2 flex items-baseline">
          <div className="text-2xl sm:text-3xl font-bold text-orange-600">
            {thisWeekPlanned.filter(activity => activity.status === 'completed').length}
          </div>
          <div className="ml-2 text-sm text-orange-500">
            / {thisWeekPlanned.length} planned
          </div>
          </div>
        </div>

        <div className="bg-white rounded-2xl shadow-sm border border-orange-100 p-6 hover:shadow-md transition-shadow hidden sm:block">
          <div className="text-sm font-medium text-gray-600">Monthly Goal</div>
          <div className="mt-2">
            <div className="flex items-center">
              <div className="text-2xl sm:text-3xl font-bold text-orange-600">75%</div>
              <div className="ml-2 text-sm text-orange-500">↑ 12%</div>
            </div>
            <div className="mt-2 h-1.5 w-full bg-orange-100 rounded-full overflow-hidden">
              <div 
                className="h-full bg-gradient-to-r from-orange-500 to-orange-400 rounded-full" 
                style={{ width: '75%' }}
              ></div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-2xl shadow-sm border border-orange-100 p-6 hover:shadow-md transition-shadow hidden sm:block">
          <div className="text-sm font-medium text-gray-600">Training Streak</div>
          <div className="mt-2 flex items-center space-x-2">
            <div className="text-2xl sm:text-3xl font-bold text-orange-600">5</div>
            <div className="flex space-x-1">
              <span className="text-2xl">🔥</span>
              <span className="text-sm text-orange-500 font-medium">Best: 8</span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Goals Section */}
          <div className="bg-white rounded-2xl shadow-sm border border-orange-100 overflow-hidden">
            <div className="border-b border-orange-100 px-6 py-4">
              <h2 className="text-lg font-semibold text-gray-900">Your Goals</h2>
            </div>
            <GoalList />
          </div>

          {/* Calendar Section */}
          <div className="bg-white rounded-2xl shadow-sm border border-orange-100 overflow-hidden">
            <div className="p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Training Calendar</h2>
              <TrainingCalendar 
                activities={activities as CompletedActivity[]}
                plannedActivities={plannedActivities as PlannedActivity[]}
              />
            </div>
          </div>
        </div>

        {/* Next Training */}
        <div className="h-fit">
          <NextTraining training={nextTraining} />
        </div>
      </div>
    </PageLayout>
  );
};

export default Dashboard;
