import React from 'react';
import { useApi } from '../../context/ApiContext.tsx';
import BaselineForm from './BaselineForm.tsx';
import ErrorScreen from '../shared/ErrorScreen.tsx';

interface BaselineCheckProps {
  children: React.ReactNode;
}

const BaselineCheck: React.FC<BaselineCheckProps> = ({ children }) => {
  const { userDetails, loading, error } = useApi();

  if (loading) return null;

  // Handle API errors
  if (error) {
    return (
      <ErrorScreen 
        error={error}
        action={{
          label: "Retry",
          onClick: () => window.location.reload()
        }}
      />
    );
  }

  if (!userDetails?.baseline) {
    return <BaselineForm />;
  }

  return <>{children}</>;
};

export default BaselineCheck; 