import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../context/ApiContext.tsx';
import { formatDistanceFromMeters } from '../../utils/activityUtils.ts';

const GoalList: React.FC = () => {
  const navigate = useNavigate();
  const { goals } = useApi();

  if (!goals?.length) {
    return (
      <div className="p-8 text-center">
        <div className="w-16 h-16 mx-auto mb-4 bg-orange-100 rounded-full flex items-center justify-center">
          <svg className="w-8 h-8 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
        </div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">No Goals Set</h3>
        <p className="text-gray-500 mb-6">Set your first running goal and let Ronny help you achieve it!</p>
        <button
          onClick={() => navigate('/add-goal')}
          className="inline-flex items-center px-4 py-2 rounded-full bg-orange-500 text-white font-medium hover:bg-orange-600 transition-colors"
        >
          Add Your First Goal
        </button>
      </div>
    );
  }

  // Sort goals: Primary goals first, then by date
  const sortedGoals = [...goals].sort((a, b) => {
    if (a.type === 'primary' && b.type !== 'primary') return -1;
    if (a.type !== 'primary' && b.type === 'primary') return 1;
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  return (
    <div className="space-y-4 p-4">
      {sortedGoals.map((goal) => (
        <div 
          key={goal.id}
          className={`
            rounded-2xl p-5 shadow-sm border transition-all duration-200
            ${goal.type === 'primary'
              ? 'bg-orange-50/50 border-orange-200'
              : 'bg-white border-gray-100'
            }
          `}
        >
          <div className="flex items-start justify-between mb-2">
            <div>
              <div className="flex items-center gap-2 mb-1">
                <span className={`
                  text-xs font-medium px-2 py-0.5 rounded-full
                  ${goal.type === 'primary'
                    ? 'bg-orange-100 text-orange-700'
                    : 'bg-blue-100 text-blue-700'
                  }
                `}>
                  {goal.type === 'primary' ? '🎯 Race Goal' : '🎪 Training Goal'}
                </span>
              </div>
              <h3 className={`text-lg font-semibold 
                ${goal.type === 'primary' ? 'text-orange-900' : 'text-gray-900'}
              `}>
                {goal.name}
              </h3>
              <p className="text-sm text-gray-600">{goal.details}</p>
            </div>
            
            <div className={`
              px-3 py-1 rounded-full text-sm font-medium
              ${goal.type === 'primary'
                ? 'bg-orange-100 text-orange-600'
                : 'bg-blue-100 text-blue-600'
              }
            `}>
              {getDaysUntil(goal.date)}
            </div>
          </div>

          <div className="flex gap-4 mt-4">
            {goal.distance && (
              <div className={`
                rounded-xl px-4 py-2
                ${goal.type === 'primary' ? 'bg-orange-100/50' : 'bg-gray-50'}
              `}>
                <div className="text-xs text-gray-500 mb-1">Distance</div>
                <div className={`
                  text-sm font-semibold
                  ${goal.type === 'primary' ? 'text-orange-900' : 'text-gray-900'}
                `}>
                  {formatDistanceFromMeters(goal.distance * 1000)} km
                </div>
              </div>
            )}

            {goal.targetTime && (
              <div className={`
                rounded-xl px-4 py-2
                ${goal.type === 'primary' ? 'bg-orange-100/50' : 'bg-gray-50'}
              `}>
                <div className="text-xs text-gray-500 mb-1">Target Time</div>
                <div className={`
                  text-sm font-semibold
                  ${goal.type === 'primary' ? 'text-orange-900' : 'text-gray-900'}
                `}>
                  {goal.targetTime}
                </div>
              </div>
            )}
          </div>

          <div className="mt-4 flex items-center space-x-2 text-sm text-gray-500">
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <span>{new Date(goal.date).toLocaleDateString()}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

// Helper function to show relative time
const getDaysUntil = (date: string): string => {
  const days = Math.ceil((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
  if (days === 0) return 'Today';
  if (days === 1) return 'Tomorrow';
  if (days < 0) return 'Past';
  if (days <= 7) return `${days} days`;
  if (days <= 30) return `${Math.ceil(days / 7)} weeks`;
  return `${Math.ceil(days / 30)} months`;
};

export default GoalList; 