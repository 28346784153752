import { useState, React } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../utils/icons';

const TrainingCalendar = ({ activities = [], plannedActivities = [] }) => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  
  const days = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'];

  // Calendar navigation
  const goToNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const goToPreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  // Get calendar data for current month
  const getDaysInMonth = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    // Get the day of week for the first day (0 = Sunday, 1 = Monday, etc.)
    let firstDayOfWeek = firstDay.getDay();
    // Adjust for Monday as first day of week
    firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

    const daysInMonth = lastDay.getDate();
    const days = [];

    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(null);
    }

    // Add the days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    return days;
  };

  // Filter activities for current month
  const getActivitiesForMonth = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const pastActivityDates = activities
      .filter(activity => {
        const activityDate = new Date(activity.date);
        return activityDate.getMonth() === month && 
               activityDate.getFullYear() === year;
      })
      .map(activity => new Date(activity.date).getDate());

    const plannedActivityDates = plannedActivities
      .filter(activity => {
        const activityDate = new Date(activity.date);
        return activityDate.getMonth() === month && 
               activityDate.getFullYear() === year;
      })
      .map(activity => new Date(activity.date).getDate());

    return { pastActivityDates, plannedActivityDates };
  };

  const handleDateClick = (date) => {
    if (!date) return;
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`;
    navigate(`/activities/${formattedDate}`);
  };

  const calendarDays = getDaysInMonth();
  const { pastActivityDates, plannedActivityDates } = getActivitiesForMonth();
  const today = new Date();
  const isCurrentMonth = currentDate.getMonth() === today.getMonth() && 
                        currentDate.getFullYear() === today.getFullYear();

  return (
    <div className="bg-white/90 backdrop-blur-sm rounded-2xl p-4 shadow-sm">
      {/* Calendar Header */}
      <div className="flex items-center justify-between mb-6">
        <button 
          onClick={goToPreviousMonth}
          className="p-2 hover:bg-orange-50/80 rounded-xl transition-colors"
        >
          {icons.chevronLeft}
        </button>
        
        <h2 className="text-base font-semibold text-gray-900">
          {currentDate.toLocaleString('nl-BE', { 
            month: 'long',
            year: 'numeric'
          }).charAt(0).toUpperCase() + 
          currentDate.toLocaleString('nl-BE', { 
            month: 'long',
            year: 'numeric'
          }).slice(1)}
        </h2>
        
        <button 
          onClick={goToNextMonth}
          className="p-2 hover:bg-orange-50/80 rounded-xl transition-colors"
        >
          {icons.chevronRight}
        </button>
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-1.5 text-center mb-4">
        {/* Day headers */}
        {days.map(day => (
          <div key={day} className="text-xs font-medium text-gray-400 pb-2">
            {day}
          </div>
        ))}
        
        {/* Calendar days */}
        {calendarDays.map((date, index) => {
          if (!date) {
            return <div key={`empty-${index}`} className="aspect-square" />;
          }

          const isToday = isCurrentMonth && date === today.getDate();
          const hasPastActivity = pastActivityDates.includes(date);
          const hasPlannedActivity = plannedActivityDates.includes(date);
          const plannedActivity = plannedActivities.find(activity => new Date(activity.date).getDate() === date);
          const isUpcoming = new Date(currentDate.getFullYear(), currentDate.getMonth(), date) > today;
          
          return (
            <div 
              key={date}
              onClick={() => handleDateClick(date)}
              className={`
                aspect-square relative cursor-pointer
                flex items-center justify-center
                transition-all duration-200
                ${isToday ? 'bg-orange-500 text-white rounded-xl shadow-sm' : ''}
                ${!isToday && (hasPastActivity || hasPlannedActivity) ? 'hover:bg-orange-50/80 rounded-xl' : ''}
                ${!isToday && !hasPastActivity && !hasPlannedActivity ? 'text-gray-400 hover:text-gray-600' : ''}
              `}
            >
              <span className={`
                text-sm relative z-10
                ${hasPastActivity && !isToday ? 'font-medium text-gray-900' : ''}
                ${isUpcoming && hasPlannedActivity ? 'font-medium text-gray-600' : ''}
              `}>
                {date}
              </span>
              
              {/* Activity indicators */}
              <div className="absolute bottom-1.5 left-1/2 transform -translate-x-1/2 flex gap-1">
              {hasPlannedActivity && !isToday && plannedActivity && (
                <>
                  {plannedActivity.status === 'completed' && (
                    <div className="w-1 h-1 rounded-full bg-green-500" />
                  )}
                  {plannedActivity.status === 'planned' && (
                    <div className="w-1 h-1 rounded-full bg-orange-400" />
                  )}
                  {plannedActivity.status === 'skipped' && (
                    <div className="w-1 h-1 rounded-full bg-gray-400" />
                  )}
                </>
              )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Legend */}
      <div className="flex gap-4 text-xs justify-center pt-2 border-t border-gray-100">
        <div className="flex items-center gap-1.5">
          <div className="w-1.5 h-1.5 rounded-full bg-green-500"></div>
          <span className="text-gray-600">Completed</span>
        </div>
        <div className="flex items-center gap-1.5">
          <div className="w-1.5 h-1.5 rounded-full bg-orange-400"></div>
          <span className="text-gray-600">Planned</span>
        </div>
      </div>
    </div>
  );
};

export default TrainingCalendar;
