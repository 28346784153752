import React from 'react';

interface DayOption {
  name: string;
  value: number;
}

const DAYS: DayOption[] = [
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
  { name: 'Sunday', value: 7 }
];

interface Props {
  selectedDays: number[];
  onDayToggle: (day: number) => void;
  isSubmitting?: boolean;
}

const DaySelector: React.FC<Props> = ({ selectedDays, onDayToggle, isSubmitting = false }) => {
  return (
    <div className="space-y-3">
      {DAYS.map((day) => (
        <label
          key={day.value}
          className={`flex items-center p-4 rounded-xl border transition-colors cursor-pointer
            ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}
            ${selectedDays.includes(day.value)
              ? 'border-blue-500 bg-blue-50'
              : 'border-gray-200 hover:border-blue-500 hover:bg-blue-50'
            }`}
        >
          <input
            type="checkbox"
            checked={selectedDays.includes(day.value)}
            onChange={() => onDayToggle(day.value)}
            disabled={isSubmitting}
            className="w-5 h-5 text-blue-600 rounded border-gray-300 
              focus:ring-blue-500 mr-4"
          />
          <span className="font-medium text-gray-900">{day.name}</span>
        </label>
      ))}
    </div>
  );
};

export default DaySelector; 