import React, { useState, useEffect } from 'react';
import { useApi } from '../context/ApiContext.tsx';
import Header from '../components/shared/Header.tsx';
import BottomNav from '../components/shared/BottomNav.tsx';
import { useNavigate } from 'react-router-dom';
import { formatDistanceFromMeters } from '../utils/activityUtils.ts';
import ConfirmDialog from '../components/shared/ConfirmDialog.tsx';
import SectionHeader from '../components/shared/SectionHeader.tsx';

interface DayMap {
  day: string;
  value: number;
}

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const { updateTrainingDays, goals, removeGoal, trainingDays: savedTrainingDays } = useApi();

  console.log('Saved training days from API:', savedTrainingDays);

  const dayMap: DayMap[] = [
    { day: 'Monday', value: 1 },
    { day: 'Tuesday', value: 2 },
    { day: 'Wednesday', value: 3 },
    { day: 'Thursday', value: 4 },
    { day: 'Friday', value: 5 },
    { day: 'Saturday', value: 6 },
    { day: 'Sunday', value: 7 },
  ];

  const [selectedDays, setSelectedDays] = useState<number[]>(() => {
    if (!savedTrainingDays?.trainingDays) return [];
    return savedTrainingDays.trainingDays;
  });

  const [longRunDay, setLongRunDay] = useState<number>(() => {
    return savedTrainingDays?.longRunDay || 6;
  });

  useEffect(() => {
    if (savedTrainingDays) {
      setSelectedDays(savedTrainingDays.trainingDays);
      setLongRunDay(savedTrainingDays.longRunDay);
    }
  }, [savedTrainingDays]);

  const [isSaving, setIsSaving] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState<string | null>(null);

  const findClosestTrainingDay = (currentDay: number, availableDays: number[]): number => {
    if (availableDays.includes(currentDay)) return currentDay;
    if (availableDays.length === 0) return currentDay;
    
    return availableDays.reduce((closest, day) => {
      const currentDiff = Math.abs(day - currentDay);
      const closestDiff = Math.abs(closest - currentDay);
      return currentDiff < closestDiff ? day : closest;
    }, availableDays[0]);
  };

  const handleDayToggle = (dayValue: number) => {
    setSelectedDays(prev => {
      const newDays = prev.includes(dayValue)
        ? prev.filter(d => d !== dayValue)
        : [...prev, dayValue].sort((a, b) => a - b);
      
      if (dayValue === longRunDay && !newDays.includes(dayValue)) {
        setLongRunDay(findClosestTrainingDay(longRunDay, newDays));
      }
      
      return newDays;
    });
  };

  const handleSaveTrainingDays = async () => {
    setIsSaving(true);
    try {
      await updateTrainingDays({
        trainingDays: selectedDays,
        longRunDay
      });
    } catch (error) {
      console.error('Failed to save training days:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteGoal = async () => {
    if (goalToDelete) {
      try {
        await removeGoal(goalToDelete);
        setGoalToDelete(null); // Close dialog after successful deletion
      } catch (error) {
        // Handle error (maybe show a toast)
        console.error('Failed to delete goal:', error);
      }
    }
  };

  const LongRunDaySelector = () => (
    <div className="mt-8 space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium text-gray-700">Long Run Day</h3>
        <span className="text-xs text-gray-500">
          Select your preferred day for long runs
        </span>
      </div>
      
      <div className="relative">
        <select
          value={longRunDay}
          onChange={(e) => setLongRunDay(Number(e.target.value))}
          className="w-full px-4 py-3 pr-10 rounded-xl border border-gray-200 
                   focus:border-orange-500 focus:ring-1 focus:ring-orange-500
                   bg-white appearance-none cursor-pointer"
        >
          {dayMap.map(({ day, value }) => (
            <option 
              key={day} 
              value={value}
              disabled={!selectedDays.includes(value)}
            >
              {day} {!selectedDays.includes(value) ? '(not a training day)' : ''}
            </option>
          ))}
        </select>
        
        {/* Custom dropdown arrow */}
        <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none">
          <svg 
            className="w-5 h-5 text-gray-400" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M19 9l-7 7-7-7" 
            />
          </svg>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 via-white to-orange-50">
      <Header title="Settings" />

      <main className="container mx-auto max-w-3xl px-4">
        <div className="pt-20 pb-20 space-y-6">
          {/* Training Goals Section */}
          <div className="bg-white rounded-2xl shadow-sm overflow-hidden border border-orange-100">
            <SectionHeader
              title="Training Goals"
              badge={{
                text: `${goals?.length || 0} goals`,
                color: 'orange'
              }}
            />
            <div className="p-6">
              <div className="space-y-4">
                {goals?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                  .map(goal => (
                    <div 
                      key={goal.id} 
                      className={`relative bg-white rounded-xl p-4 border
                        ${goal.type === 'primary' 
                          ? 'border-orange-200 bg-orange-50/50' 
                          : 'border-gray-200'
                        }`}
                    >
                      {/* Type Badge */}
                      <div className={`absolute top-4 right-4 px-2 py-1 rounded-full text-xs font-medium
                        ${goal.type === 'primary'
                          ? 'bg-orange-100 text-orange-800'
                          : 'bg-gray-100 text-gray-600'
                        }`}
                      >
                        {goal.type === 'primary' ? '🎯 Primary' : '🎪 Support'}
                      </div>

                      <div className="space-y-2">
                        <div className="flex items-start justify-between">
                          <div>
                            <h3 className="font-medium text-gray-900">{goal.name}</h3>
                            <p className="text-sm text-gray-600">{goal.details}</p>
                          </div>
                        </div>

                        <div className="flex items-center space-x-4 text-sm text-gray-500">
                          <div className="flex items-center">
                            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                            </svg>
                            <span>{formatDistanceFromMeters(goal.distance)}</span>
                          </div>

                          <div className="flex items-center">
                            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <span>{new Date(goal.date).toLocaleDateString()}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                
                <button
                  onClick={() => navigate('/add-goal')}
                  className="w-full py-3 px-4 bg-gradient-to-r from-orange-500 to-orange-600 
                           hover:from-orange-600 hover:to-orange-700 text-white rounded-xl 
                           font-medium transition-all duration-200 shadow-sm hover:shadow"
                >
                  Add New Goal
                </button>
              </div>
            </div>
          </div>

          {/* Training Days Section */}
          <div className="bg-white rounded-2xl shadow-sm overflow-hidden border border-orange-100">
            <SectionHeader
              title="Training Days"
              badge={{
                text: `${selectedDays.length} active days`,
                color: 'orange'
              }}
            />
            <div className="p-6">
              <div className="space-y-3">
                {dayMap.map(({ day, value }) => (
                  <label 
                    key={day}
                    className={`
                      flex items-center justify-between p-4 
                      backdrop-blur-sm rounded-xl cursor-pointer 
                      transition-all duration-200 border 
                      ${selectedDays.includes(value)
                        ? 'bg-orange-50/50 border-orange-100 hover:bg-orange-50/80' 
                        : 'bg-gray-50/50 border-gray-100 hover:bg-gray-50/80'
                      }
                    `}
                  >
                    <div className="flex items-center space-x-3">
                      <div className={`
                        w-8 h-8 rounded-full flex items-center justify-center
                        transition-colors duration-200
                        ${selectedDays.includes(value)
                          ? 'bg-gradient-to-r from-orange-500 to-orange-600 text-white' 
                          : 'bg-gray-100 text-gray-400'
                        }
                      `}>
                        {day.charAt(0)}
                      </div>
                      <span className={`
                        font-medium transition-colors duration-200
                        ${selectedDays.includes(value) ? 'text-orange-900' : 'text-gray-500'}
                      `}>
                        {day}
                      </span>
                    </div>

                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={selectedDays.includes(value)}
                        onChange={() => handleDayToggle(value)}
                        className="sr-only"
                      />
                      <div className={`
                        w-12 h-7 rounded-full 
                        transition-all duration-300 ease-in-out
                        ${selectedDays.includes(value)
                          ? 'bg-gradient-to-r from-orange-500 to-orange-600' 
                          : 'bg-gray-200'
                        }
                        shadow-inner
                      `}>
                        <div className={`
                          w-5 h-5 rounded-full bg-white 
                          shadow-sm transform transition-transform duration-300 ease-in-out
                          absolute top-1 
                          ${selectedDays.includes(value) ? 'translate-x-6' : 'translate-x-1'}
                        `} />
                      </div>
                    </div>
                  </label>
                ))}

                <LongRunDaySelector />

                <button
                  onClick={handleSaveTrainingDays}
                  disabled={isSaving}
                  className={`
                    w-full mt-6 py-3.5 px-4 rounded-xl font-medium 
                    text-white transition-all duration-200 
                    shadow-sm hover:shadow flex items-center justify-center space-x-2
                    ${isSaving 
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : 'bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700'
                    }
                  `}
                >
                  {isSaving ? (
                    <>
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Saving...</span>
                    </>
                  ) : (
                    <>
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span>Save Training Days</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>

      <ConfirmDialog
        isOpen={!!goalToDelete}
        onClose={() => setGoalToDelete(null)}
        onConfirm={handleDeleteGoal}
        title="Delete Goal"
        message="Are you sure you want to delete this goal? This action cannot be undone."
      />

      <BottomNav />
    </div>
  );
};

export default SettingsPage; 