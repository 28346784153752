import React from 'react';

interface ErrorScreenProps {
  error: Error | string;
  onRetry?: () => void;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ error, onRetry }) => {
  return (
    <div className="fixed inset-0 bg-white flex items-center justify-center p-4">
      <div className="max-w-md w-full space-y-6 text-center">
        <div className="text-5xl mb-8">😕</div>
        
        <div className="space-y-2">
          <h1 className="text-xl font-bold text-gray-900">
            Oops! Something went wrong
          </h1>
          <p className="text-gray-600 text-sm">
            {typeof error === 'string' ? error : error.message}
          </p>
        </div>

        <div className="space-y-4 pt-4">
          {onRetry && (
            <button
              onClick={onRetry}
              className="w-full py-3 px-4 bg-blue-600 text-white rounded-xl 
                font-medium hover:bg-blue-700 transition-colors shadow-sm"
            >
              Try Again
            </button>
          )}
          
          <button
            onClick={() => window.location.reload()}
            className="w-full py-3 px-4 bg-gray-100 text-gray-700 rounded-xl 
              font-medium hover:bg-gray-200 transition-colors"
          >
            Refresh Page
          </button>

          <div className="text-sm text-gray-500 pt-4">
            If the problem persists, please contact support
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen; 