const API_URL = process.env.REACT_APP_API_URL;

const handleTokenExpiration = () => {
  console.log('Token expired, removing jwt');
  localStorage.removeItem('jwt');
};

// Helper function to handle API responses
const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json().catch(() => ({}));
    console.log(error);
    if (error.code === 401) {
      handleTokenExpiration();

      return;
    }

    throw new Error(error.message || 'An error occurred');
  }
  return response.json();
};

// Helper to get auth headers
const getAuthHeaders = () => {
  const token = localStorage.getItem('jwt');
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

// API methods
export const api = {
  // Auth related endpoints
  auth: {
    getLoginUrl: () => `${API_URL}/connect/dash`,
    logout: () => {
      localStorage.removeItem('jwt');
      window.location.href = '/';
    },
  },

  users: {
    getCurrentUser: async () => {
      const response = await fetch(`${API_URL}/api/me`, {
        headers: getAuthHeaders(),
      });
      return handleResponse(response);
    },
    setBaseline: async (data) => {
      const response = await fetch(`${API_URL}/api/me/baseline`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    },
  },

  activities: {
    getPastActivities: async () => {
      const response = await fetch(`${API_URL}/api/activities`, {
        headers: getAuthHeaders(),
      });
      return handleResponse(response);
    },
    getPlannedActivities: async () => {
      const response = await fetch(`${API_URL}/api/planned`, {
        headers: getAuthHeaders(),
      });
      return handleResponse(response);
    },
    syncActivities: async () => {
      const response = await fetch(`${API_URL}/api/sync-activities`, {
        method: 'POST',
        headers: getAuthHeaders(),
      });
      return handleResponse(response);
    },
    regenerateActivity: async (activityId, reason) => {
      const response = await fetch(`${API_URL}/api/activity/${activityId}/regenerate`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ reason }),
      });
      return handleResponse(response);
    },
    markActivitySkipped: async (activityId) => {
      const response = await fetch(`${API_URL}/api/activity/${activityId}/mark-skipped`, {
        method: 'POST',
        headers: getAuthHeaders(),
      });
      return handleResponse(response);
    },
  },

  goals: {
    postGoal: async (goal) => {
      try {
        const response = await fetch(`${API_URL}/api/goals`, {
          method: 'POST',
          headers: getAuthHeaders(),
          body: JSON.stringify(goal),
        });

        if (!response.ok) {
          throw new Error('Failed to add goal');
        }

        return await response.json();
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
    getGoals: async () => {
      try {
        const response = await fetch(`${API_URL}/api/goals`, {
          method: 'GET',
          headers: getAuthHeaders(),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch goals');
        }

        return await response.json();
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
    deleteGoal: async (goalId) => {
      try {
        const response = await fetch(`${API_URL}/api/goals/${goalId}`, {
          method: 'DELETE',
          headers: getAuthHeaders(),
        });

        if (!response.ok) {
          throw new Error('Failed to delete goal');
        }

        return true;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  },

  weekPlan: {
    getWeekPlan: async () => {
      const response = await fetch(`${API_URL}/api/week-plan`, {
        headers: getAuthHeaders(),
      });
      return handleResponse(response);
    },
    submitWeekFeedback: async (weekId, feedback) => {
      const response = await fetch(`${API_URL}/api/week/${weekId}/feedback`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ feedback }),
      });
      return handleResponse(response);
    },
  },

  settings: {
    getTrainingDays: async () => {
      try {
        const response = await fetch(`${API_URL}/api/training-days`, {
          method: 'GET',
          headers: getAuthHeaders(),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch training days');
        }

        return await response.json();
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },

    updateTrainingDays: async (trainingDays) => {
      try {
        const response = await fetch(`${API_URL}/api/training-days`, {
          method: 'POST',
          headers: getAuthHeaders(),
          body: JSON.stringify({ trainingDays }),
        });

        if (!response.ok) {
          throw new Error('Failed to update training days');
        }

        return await response.json();
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  },
};
