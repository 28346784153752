import React from 'react';
import { TrainingBlockType } from '../../types/activity';
import { formatDistance, formatPace, getCompletedBlockType } from '../../utils/activityUtils.ts';

interface BlockStyle {
  accentColor: string;
  bgColor: string;
  icon: JSX.Element;
  label: string;
}

const blockStyles: Record<TrainingBlockType, BlockStyle> = {
  warmup: {
    accentColor: 'bg-blue-400/90',
    bgColor: 'bg-blue-50/80',
    icon: (
      <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" 
        />
      </svg>
    ),
    label: 'Warm-up'
  },
  interval: {
    accentColor: 'bg-purple-400/90',
    bgColor: 'bg-purple-50/80',
    icon: (
      <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M13 10V3L4 14h7v7l9-11h-7z" 
        />
      </svg>
    ),
    label: 'Interval'
  },
  cooldown: {
    accentColor: 'bg-green-400/90',
    bgColor: 'bg-green-50/80',
    icon: (
      <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" 
        />
      </svg>
    ),
    label: 'Cool-down'
  },
  recovery: {
    accentColor: 'bg-yellow-400/90',
    bgColor: 'bg-yellow-50/80',
    icon: (
      <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" 
        />
      </svg>
    ),
    label: 'Recovery'
  },
  main: {
    accentColor: 'bg-orange-400/90',
    bgColor: 'bg-orange-50/80',
    icon: (
      <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M13 10V3L4 14h7v7l9-11h-7z" 
        />
      </svg>
    ),
    label: 'Main'
  }
};

interface Props {
  block: any;
  index: number;
  defaultPace: number;
}

const TrainingBlock: React.FC<Props> = ({ block, index, defaultPace }) => {
  const type = block.type || getCompletedBlockType(block, '6');
  const style = blockStyles[type];

  return (
    <div className={`${style.bgColor} backdrop-blur-sm rounded-lg p-2.5`}>
      <div className="flex items-start gap-2">
        <div className={`${style.accentColor} p-1.5 rounded-md shadow-sm`}>
          {style.icon}
        </div>
        <div className="flex-1 min-w-0">
          <div className="flex items-center gap-1.5">
            <div className="text-xs font-medium text-gray-500 uppercase tracking-wide">
              {style.label}
            </div>
            <div className="text-xs text-gray-700 font-medium">
              • {formatDistance(block.distance)} km at {formatPace(block.pace)}
            </div>
          </div>
          {block.notes && (
            <div className="text-xs text-gray-500 mt-0.5">
              {block.notes}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainingBlock;
  