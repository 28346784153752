import React from 'react';
import { PlannedActivity } from '../../types/activity.ts';
import { formatDistanceFromMeters, formatPace } from '../../utils/activityUtils.ts';
import { useNavigate } from 'react-router-dom';

interface Props {
  training: PlannedActivity;
  showViewDetails?: boolean;
} 

const TrainingCard: React.FC<Props> = ({ training, showViewDetails = true }) => {
  const navigate = useNavigate();

  const statusStyles = {
    completed: { color: 'text-green-600', bg: 'bg-green-50', icon: '✓', text: 'Completed' },
    planned: { color: 'text-orange-600', bg: 'bg-orange-50', icon: '◷', text: 'Planned' },
    skipped: { color: 'text-gray-500', bg: 'bg-gray-50', icon: '↷', text: 'Skipped' }
  };

  const trainingTypeStyles = {
    interval: { color: 'bg-orange-600 text-white', icon: '⚡' },
    tempo: { color: 'bg-orange-500 text-white', icon: '🎯' },
    long: { color: 'bg-orange-500 text-white', icon: '🏃‍♂️' },
    easy: { color: 'bg-orange-100 text-orange-800', icon: '🏃‍♂️' },
    recovery: { color: 'bg-gray-200 text-gray-700', icon: '🔄' },
    race: { color: 'bg-red-500 text-white', icon: '🏁' }
  };

  const blockTypeStyles = {
    warmup: 'bg-orange-200',
    interval: 'bg-orange-600',
    cooldown: 'bg-orange-100',
    main: 'bg-orange-500',
    recovery: 'bg-gray-200',
    race: 'bg-red-500'
  };

  const totalDistance = training.blocks.reduce((total, block) => total + Number(block.distance), 0) * 1000;

  // Format date to be more readable
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (date.toDateString() === today.toDateString()) return 'Today';
    if (date.toDateString() === tomorrow.toDateString()) return 'Tomorrow';
    
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="bg-white/90 backdrop-blur-sm rounded-2xl p-4 shadow-sm border border-orange-100">
      <div className="flex flex-wrap items-center gap-2 mb-3">
        <div className={`inline-flex items-center px-2 py-0.5 text-xs rounded-full ${trainingTypeStyles[training.type].color}`}>
          <span className="mr-1">{trainingTypeStyles[training.type].icon}</span>
          <span className="font-medium capitalize">{training.type}</span>
        </div>
        <div className={`inline-flex items-center px-2 py-0.5 text-xs rounded-full ${statusStyles[training.status].bg}`}>
          <span className={`font-medium ${statusStyles[training.status].color}`}>
            {statusStyles[training.status].icon} {statusStyles[training.status].text}
          </span>
        </div>
        <span className="px-2 py-0.5 bg-orange-50 text-orange-600 rounded-full text-xs font-medium ml-auto">
          {formatDate(training.date)}
        </span>
      </div>

      <div className="space-y-3">
        {/* Quick stats */}
        <div className="grid grid-cols-2 gap-3">
          <div className="bg-orange-50/50 rounded-xl p-3">
            <div className="flex items-center text-gray-500 text-sm mb-1">
              <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M13 10V3L4 14h7v7l9-11h-7z" 
                />
              </svg>
              Distance
            </div>
            <div className="font-semibold text-gray-900">
              {formatDistanceFromMeters(totalDistance)}
            </div>
          </div>
          
          <div className="bg-orange-50/50 rounded-xl p-3">
            <div className="flex items-center text-gray-500 text-sm mb-1">
              <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" 
                />
              </svg>
              Target Pace
            </div>
            <div className="font-semibold text-gray-900">
              {formatPace(training.targetPace)}
            </div>
          </div>
        </div>

        {/* Blocks visualization */}
        <div className="space-y-2">
          <div className="flex space-x-1">
            {training.blocks.map((block, index) => (
              <div 
                key={index}
                className={`h-2 rounded-full flex-1 ${blockTypeStyles[block.type]} ${training.status === 'skipped' ? 'opacity-50' : ''}`}
                title={`${block.distance}km at ${block.pace}/km`}
              />
            ))}
          </div>
        </div>

        {/* View Details Link */}
        {showViewDetails && (
          <div 
            onClick={() => navigate(`/activities/${training.date}`)}
            className="flex items-center justify-between py-2 text-sm text-orange-600 hover:text-orange-500 cursor-pointer"
          >
            <span className="font-medium">View Details</span>
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingCard; 