import React, { useState } from 'react';

interface Badge {
  text: string;
  color: 'blue' | 'green' | 'purple' | 'orange';
}

interface SectionHeaderProps {
  title: string;
  badge?: Badge;
  action?: React.ReactNode;
  collapsible?: boolean;
  defaultOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ 
  title, 
  badge, 
  action,
  collapsible = false,
  defaultOpen = true,
  onToggle
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleToggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    onToggle?.(newState);
  };

  const badgeColors = {
    blue: 'bg-blue-100 text-blue-700',
    green: 'bg-green-100 text-green-700',
    purple: 'bg-purple-100 text-purple-700',
    orange: 'bg-orange-100 text-orange-700'
  };

  return (
    <div 
      className={`
        px-6 py-4 border-b border-gray-100 flex items-center justify-between
        ${collapsible ? 'cursor-pointer hover:bg-gray-50/50' : ''}
      `}
      onClick={collapsible ? handleToggle : undefined}
    >
      <div className="flex items-center space-x-3">
        <h2 className="font-semibold text-gray-900">{title}</h2>
        {badge && (
          <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${badgeColors[badge.color]}`}>
            {badge.text}
          </span>
        )}
      </div>
      <div className="flex items-center space-x-3">
        {action}
        {collapsible && (
          <svg
            className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${
              isOpen ? 'transform rotate-180' : ''
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default SectionHeader; 